<template>
  <a-row :gutter="6" style="width: 100%">
    <a-col :span="8">
      <a-select placeholder="省" v-model="expectedDeliveryProvince" @change="changeInfo" :disabled="disabled">
        <img src="/Polygon.png" alt="" class="icon-arrow-tag" slot="suffixIcon">
        <a-select-option :value="i.regionId" v-for="i in expectedDeliveryProvinceList" :key="i.regionId">{{ i.regionName
        }}</a-select-option>
      </a-select>
    </a-col>
    <a-col :span="8">
      <a-select placeholder="市" v-model="expectedDeliveryCity" @change="handleLoadCity('expectedDeliveryDistrict')" :disabled="disabled">
        <img src="/Polygon.png" alt="" class="icon-arrow-tag" slot="suffixIcon">
        <a-select-option :value="i.regionId" v-for="i in expectedDeliveryCityList" :key="i.regionId">{{ i.regionName
        }}</a-select-option>
      </a-select>
    </a-col>
    <a-col :span="8">
      <a-select placeholder="区" v-model="expectedDeliveryDistrict" @change="handleChange" :disabled="disabled">
        <img src="/Polygon.png" alt="" class="icon-arrow-tag" slot="suffixIcon">
        <a-select-option :value="i.regionId" v-for="i in expectedDeliveryDistrictList" :key="i.regionId">{{ i.regionName
        }}</a-select-option>
      </a-select>
    </a-col>
  </a-row>
</template>

<script>
import { getAction } from '@/api/manage.js'
export default {
  name: 'ProvincePicker',
  props: {
    placeholder: {
      type: String,
      default: '请输入'
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    let dateStr = this.value && this.value.split('-') || []
    if (dateStr.length) {
      this.expectedDeliveryProvince = dateStr[0] || undefined;

      this.handleLoadCity('expectedDeliveryCity')
      this.handleLoadCity('expectedDeliveryDistrict', false, dateStr[1])
    }
    return {
      expectedDeliveryProvince: dateStr[0] || undefined,
      expectedDeliveryCity: dateStr[1] || undefined,
      expectedDeliveryDistrict: dateStr[2] || undefined,
      expectedDeliveryProvinceList: [],
      expectedDeliveryCityList: [],
      expectedDeliveryDistrictList: []
    }
  },
  watch: {
    value(val) {
      if (!val) {
        this.expectedDeliveryProvince = undefined;
        this.expectedDeliveryCity = undefined;
        this.expectedDeliveryDistrict = undefined;
      } else {
        const [expectedDeliveryProvince, expectedDeliveryCity, expectedDeliveryDistrict] = val.split('-')
        this.expectedDeliveryProvince = expectedDeliveryProvince;
        this.expectedDeliveryCity = expectedDeliveryCity;
        this.expectedDeliveryDistrict = expectedDeliveryDistrict;
      }
    },

  },
  created() {
    this.loadRegionList();
  },
  methods: {
    loadRegionList() {
      getAction('/area/queryByLevel', {
        level: 1,
      }).then((res) => {
        const { success, data } = res
        if (success) {
          this.expectedDeliveryProvinceList = data
        }
      })
    },
    changeInfo(e) {
      this.expectedDeliveryProvince = e
      this.handleUpdateView()
      this.handleChangeRootId()
    },
    handleChangeTab() {
      this.expectedDeliveryCityList = []
      this.expectedDeliveryDistrictList = []
      this.expectedDeliveryProvince && this.handleChangeRootId(true)
      this.expectedDeliveryDistrict && this.handleLoadCity('deliveryDistrict', true)
    },
    handleChangeRootId(flag = false) {
      this.expectedDeliveryCityList = []
      if (!flag) {
        this.deliveryDistrictList = []
        this.expectedDeliveryCity = undefined
        this.expectedDeliveryDistrict = undefined
      }
      this.handleLoadCity('expectedDeliveryCity', flag)
      this.expectedDeliveryDistrict && this.handleLoadCity('expectedDeliveryDistrict', flag)
    },
    handleUpdateView() {
      this.$forceUpdate()
    },
    handleChange() {
      this.$emit('change', `${this.expectedDeliveryProvince}-${this.expectedDeliveryCity}-${this.expectedDeliveryDistrict}`)
    },
    handleLoadCity(key, flag = false, parentIds = null) {
      let parentId = ''
      if (key == 'expectedDeliveryCity') {
        this.expectedDeliveryCityList = []
        this.expectedDeliveryDistrictList = []
        !flag && (this.expectedDeliveryCity = undefined)
        parentId = this.expectedDeliveryProvince
      }
      if (key == 'expectedDeliveryDistrict') {
        this.deliveryDistrictList = []
        !flag && (this.expectedDeliveryDistrict = undefined)
        parentId = parentIds || this.expectedDeliveryCity
      }
      getAction('/area/queryByParentIdList', {
        parentId,
      }).then((res) => {
        const { success, data } = res
        if (success) {
          this[`${key}List`] = data
        }
      })
    },
  },
  model: {
    prop: 'value',
    event: 'change'
  }
}
</script>

<style lang="less" scoped></style>